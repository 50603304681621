<template>
  <div class="loading-container">
    <a-spin size="large" />
  </div>
</template>

<script>
export default {
  name: 'LoadingContainer',
};
</script>

<style lang="scss" scoped>
.loading-container {
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
